<template lang="pug">
TheBook(
	:title="ships.title"
	:endpoint="ships.endpoint"
	:path="ships.path"
	:left="ships.left"
	:leftSub="ships.leftSub"
	:leftSubTitle="ships.leftSubTitle"
)
</template>

<script>
import TheBook from '../components/TheBook'
import BOOKS from '../modules/constants/books'

export default {
	name: 'Workers',
	components: { TheBook },
	setup () {
		const { ships } = BOOKS

		return {
			ships
		}
	}
}
</script>
